import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getModule} from 'vuex-module-decorators';
import nomenclatoare from '@/store/nomenclatoare';
@Component({components: {}})
export default class MenuApp extends Vue {
    @Prop({ default: '0' }) public readonly userid!: string;
    @Prop() public onCloseMenu!: ()=>void;

    public $refs: any;
    public nomStore = getModule(nomenclatoare);
    public resetChitanta(){
        this.nomStore.reset_chitanta();
        this.onCloseMenu();
    }

    public GoToAdminFamilies(){
        this.$router.push({ name: 'Families' })
    }

    public created(): void {
        const vueInst = this;
    }


}
