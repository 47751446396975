import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5240da53&scoped=true&"
import script from "./app.ts?vue&type=script&lang=ts&"
export * from "./app.ts?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&id=5240da53&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5240da53",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QMenu,QToggle,QSeparator,QTooltip,QToolbarTitle,QIcon,QPageContainer,QDialog,QCard,QBar,QCardSection});qInstall(component, 'directives', {ClosePopup});
