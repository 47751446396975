import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import {getModule} from 'vuex-module-decorators';
import nomenclatoare from '@/store/nomenclatoare';
import {TFamily} from "@/types/TFamily";


@Component({components: {}})
export default class Families extends Vue {
    public thumbStyle= {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#027be3',
        width: '5px',
        opacity: 0.75
    };

    public barStyle= {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#027be3',
        width: '9px',
        opacity: 0.2
    };

    public nomStore = getModule(nomenclatoare);


    public get nomFamilies(): Array<TFamily> {
        return this.nomStore.nomFamilies;
    }

    public removeFamily(pFamily:TFamily){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergeti ${pFamily.name} ?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            vueInst.$q.loading.show();
            vueInst.nomStore.remove_family(pFamily)
            vueInst.$q.loading.hide();
            this.$q.notify({
                color: 'teal',
                textColor: 'white',
                icon: 'positive',
                position: 'top',
                timeout: 500,
                message: 'Eliminat!'
            })
        })
    }




    public created(): void {
        const vueInst = this;

    }

}
