import Vue from 'vue'
import {store} from './index';
import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import {TMancare} from '@/types/TMancare';
import {TFamily} from "@/types/TFamily";
type TFamilyResult={
    family:string,
    amount:number
}


@Module({ namespaced: true, dynamic: true, store, name: 'storeNomenclatoare'})
export default class Nomenclatoare extends VuexModule {
    public currentPageTitle ='';
    public nomFamilies: Array<TFamily>=[]
    public chitanta:TMancare[]=[]


    @Mutation
    public PUSH_NEW_FAMILY(pfamily: TFamily) {
        const vueInst=this;
        this.nomFamilies.push(pfamily);
        localStorage.setItem("nomFamilies", JSON.stringify(vueInst.nomFamilies));
    }
    @Action
    public push_new_family(pfamily: TFamily) {
        this.context.commit('PUSH_NEW_FAMILY', pfamily);
    }

    @Mutation
    public SET_FAMILIES_FROM_LOCALSTORAGE(pfamilies: Array<TFamily>) {
        const vueInst=this;
        vueInst.nomFamilies=JSON.parse(JSON.stringify(pfamilies));
    }
    @Action
    public set_families_from_localStorage(pfamilies: Array<TFamily>) {
        this.context.commit('SET_FAMILIES_FROM_LOCALSTORAGE', pfamilies);
    }

    @Mutation
    public REMOVE_FAMILY(pfamily: TFamily) {
        const vueInst=this;
        this.nomFamilies.forEach((family,pindex)=>{
            if(family.name==pfamily.name){
                vueInst.nomFamilies.splice(pindex,1)
            }
        })
        localStorage.setItem("nomFamilies", JSON.stringify(vueInst.nomFamilies));
        this.chitanta.forEach((chitanta,pindex)=>{
            if(chitanta.family==pfamily.name){
                this.chitanta[pindex].family='';
            }
        });
    }
    @Action
    public remove_family(pfamily: TFamily) {
        this.context.commit('REMOVE_FAMILY', pfamily);
    }

    @Mutation
    public RESET_CHITANTA() {
        const vueInst=this;
        this.chitanta=[];
        localStorage.setItem("chitanta", JSON.stringify(vueInst.chitanta));
        this.nomFamilies.forEach((family,pindex)=>{
            this.nomFamilies[pindex].amount=0;
        })
    }
    @Action
    public reset_chitanta() {
        this.context.commit('RESET_CHITANTA');
    }


    @Mutation
    public ADD_MANCARE(pMancare: TMancare) {
        const vueInst=this;
        const unix = Math.random();
        pMancare.appid=unix.toString()
        this.chitanta.push(pMancare)
        localStorage.setItem("chitanta", JSON.stringify(vueInst.chitanta));
    }
    @Action
    public add_mancare(pMancare: TMancare) {
        this.context.commit('ADD_MANCARE', pMancare);
    }

    @Mutation
    public DO_CALCULATION(nimic:string) {
        const vueInst=this;
        this.nomFamilies.forEach((family,pindex)=>{
            this.nomFamilies[pindex].amount=0;
        })
        vueInst.chitanta.forEach(mancare=>{

            this.nomFamilies.forEach((pfamily,pindex)=>{
                if(pfamily.name==mancare.family){
                    this.nomFamilies[pindex].amount=Number(this.nomFamilies[pindex].amount)+Number(mancare.amount);
                }

            })
        })
    }
    @Action
    public do_calculation(nimic:string) {
        this.context.commit('DO_CALCULATION',nimic);
    }

    @Mutation
    public REMOVE_MANCARE(pMancare: TMancare) {
        const vueInst=this;
        let index=0;
        this.chitanta.forEach((mancare,pindex)=>{
            if(mancare.appid==pMancare.appid){
                index=pindex;
            }
        });
        this.chitanta.splice(index,1);
        localStorage.setItem("chitanta", JSON.stringify(vueInst.chitanta));

    }
    @Action
    public remove_mancare(pMancare: TMancare) {
        this.context.commit('REMOVE_MANCARE', pMancare);
        //this.do_calculation('nimic');
        this.context.commit('DO_CALCULATION','nimic');
    }

    @Mutation
    public ASSIGN_TO_FAMILY(pMancare: TMancare) {
        const vueInst=this;
        this.chitanta.forEach((mancare,index)=>{
            if(mancare.appid==pMancare.appid){
                console.log('in store, ASSIGN_TO_FAMILY, am gasit la index %o pentru pMancare=%o',index,pMancare)
                //[index].family=pMancare.family
                Vue.set(this.chitanta, index, pMancare)
            }
        })
        localStorage.setItem("chitanta", JSON.stringify(vueInst.chitanta));
        //this.context.commit('DO_CALCULATION','nimic');

        }
    @Action
    public assign_to_family(pMancare: TMancare) {
        this.context.commit('ASSIGN_TO_FAMILY', pMancare);
        this.do_calculation('nimic');
    }
}
