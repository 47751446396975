import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import {getModule} from 'vuex-module-decorators';
import {TMancare} from '@/types/TMancare';
import nomenclatoare from '@/store/nomenclatoare';
type TFamilyResult={
    family:string,
    amount:number
}

@Component({components: {}})
export default class Home extends Vue {
    @Prop({ default: '0' }) public readonly userid!: string;
    @Prop() public onCloseMenu!: ()=>void;
    public loading=false;
    public dialogAddMancare=false;
    public hrefForWhatsapp='';
    public showDialogShareWhatsApp=false;
    public $refs: any;
    public nomStore = getModule(nomenclatoare);

    public newMancare:TMancare={
        appid:'0',
        amount:0,
        family:'',
        meaning:''
    }

    public get nomFamilies(): Array<string> {
        return this.nomStore.nomFamilies.map(family=>family.name);
    }

    public get chitanta(): Array<TMancare> {
        return this.nomStore.chitanta;
    }

    public get totalValoareChitanta(): string {
        const result= this.nomStore.chitanta.reduce((a, b) => Number(a) + Number(b.amount), 0);
        return result.toFixed(1)
    }

    public get restUnsplittedChitanta(): Array<TMancare> {
        return this.nomStore.chitanta;
    }

    public addRow(){
        this.onReset();
        this.dialogAddMancare=true;
    }

    public makeId(length: number) {
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    public shareBill(){
        const vueInst=this;
        const BilldId = vueInst.makeId(10)
        let LinkTextToShare='';
            vueInst.loading=true;
            const nomFamiliesLocalStorage=localStorage.getItem("nomFamilies");
            const chitantaLocalStorage=localStorage.getItem("chitanta");
            if(nomFamiliesLocalStorage && nomFamiliesLocalStorage!='undefined'){
                const nomFamiliesTmp=JSON.parse(nomFamiliesLocalStorage);
                if(nomFamiliesLocalStorage && nomFamiliesLocalStorage!='undefined' && chitantaLocalStorage && chitantaLocalStorage!='undefined') {
                    const arrChitanta=JSON.parse(chitantaLocalStorage);
                    vueInst.loading=true;
                    fetch("https://grecia.theappsonline.com/storebill.php?id="+BilldId,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            method: "POST",
                            body: JSON.stringify({nomFamilies: nomFamiliesTmp, chitanta: arrChitanta})
                        })
                        .then(function(res){
                            vueInst.loading=false;
                            vueInst.$q.loading.hide();
                            //if(vueInst.$q.platform.is.mobile){
                                //LinkTextToShare = 'https://api.whatsapp.com/send?text=' + encodeURIComponent('https://grecia.theappsonline.com?id='+BilldId) ;
                                LinkTextToShare = 'whatsapp://send?text=link acces: ' + encodeURIComponent('https://grecia.theappsonline.com?id='+BilldId) ;
                           // }else{
                            //    LinkTextToShare = 'https://web.whatsapp.com/send?text=' + encodeURIComponent('https://grecia.theappsonline.com?id='+BilldId) ;
                            //}
                            vueInst.hrefForWhatsapp=LinkTextToShare;
                            vueInst.showDialogShareWhatsApp=true;
                            //window.open(LinkTextToShare,"_blank");
                        })
                        .catch(function(res){ console.log(res) })
                }
                else{
                    vueInst.loading=false;
                }
            }else{
                vueInst.loading=false;
            }
    }


    public assignToFamily(mancare:TMancare){
        this.nomStore.assign_to_family(mancare);
    }

    public onSubmit () {
        const vueInst=this;
        const unix = Math.random();
        vueInst.nomStore.add_mancare(vueInst.newMancare);
        this.dialogAddMancare=false;

    }

    removeMancare(mancare:TMancare){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergeti ${mancare.meaning} cu valoarea ${mancare.amount}?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            vueInst.$q.loading.show();
            vueInst.nomStore.remove_mancare(mancare);
            vueInst.$q.loading.hide();
            this.$q.notify({
                color: 'teal',
                textColor: 'white',
                icon: 'positive',
                position: 'top',
                timeout: 500,
                message: 'Eliminat!'
            })
        })
    }

    public onReset(){
        this.newMancare={
            appid:'0',
            amount:0,
            family:'',
            meaning:''
        }
    }


    public created(): void {
        const vueInst = this;

    }

}
