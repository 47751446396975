import Vue from 'vue'
import Component from 'vue-class-component'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from './router';
import {store} from './store'
import interceptors from './modules/interceptors';
import VuePageTransition from 'vue-page-transition';
import './quasar'
import './assets/site.scss';
interceptors();
Vue.config.productionTip = false;
Vue.use(VuePageTransition);
Vue.use(VueRouter);
Vue.prototype.$eventHub = new Vue(); // Global event bus
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate' // for vue-router 2.2+
]);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
