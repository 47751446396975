import { Component, Vue } from 'vue-property-decorator';
import { getModule} from 'vuex-module-decorators';
import {TFamily} from "@/types/TFamily";
import nomenclatoare from '@/store/nomenclatoare';
import {TFamilyResult} from '@/types/TFamilyResult';
import MenuApp from './components/MenuApp/MenuApp.vue';
import Home from './components/Home/Home.vue';
import Families from './components/Families/Families.vue';
import {TMancare} from '@/types/TMancare';
import NewFamily from "@/components/NewFamily/NewFamily.vue";


@Component({components: {MenuApp, Home, Families, NewFamily}})
export default class App extends Vue {
    public leftDrawerOpen = false;
    public dialogShowAddNewFamily = false;
    public automateSplitterTips=false;
    public detailsForFamily:TMancare[]=[];
    public currentPageTitle= '';
    public inputFastSearch = '';
    public dialogShowfamily=false;
    public selectedFamily = '';
    public resetEverythingModel=false;
    public $refs: any;
    public storeNomenclatoare = getModule(nomenclatoare);
    public nomStore = getModule(nomenclatoare);
    constructor() {
        super();
    }

    public onCloseNewFamily(){
        this.dialogShowAddNewFamily = false;
        this.$refs.MenuSettings.hide();
        this.$q.loading.hide();
    }

    public get nomFamilies(): Array<TFamily> {
        return this.nomStore.nomFamilies;
    }

    public showDialogAddNewFamily(){
        this.dialogShowAddNewFamily=true;
    }

    public AskIfResetChitanta(){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergem si deschidem o chitanta noua?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            vueInst.$q.loading.show();
            this.nomStore.reset_chitanta();
            this.onCloseMenu();
            vueInst.$q.loading.hide();
            this.$q.notify({
                color: 'teal',
                textColor: 'white',
                icon: 'positive',
                position: 'top',
                timeout: 500,
                message: 'Chitanta noua creata!'
            })
        })
    }

    public resetEverything(){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergeti totul (familii si chitante) si reincarcam aplicatia?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            vueInst.$q.loading.show();
            localStorage.setItem("nomFamilies", '');
            localStorage.setItem("chitanta", '');
            location.reload();
        }).onCancel(()=>{
            vueInst.resetEverythingModel=!vueInst.resetEverythingModel;
        })
    }

    public showFamily(pFamily:TFamily){
       console.log('showFamily with pFamily=%o',pFamily)
        this.dialogShowfamily=true;
        this.selectedFamily=pFamily.name;
        this.detailsForFamily=this.nomStore.chitanta.filter(family=>{
            return pFamily.name==family.family;
        })
    }

    public get totalForSelectedFamily():number{
        const vueInst=this;
        return  vueInst.detailsForFamily.reduce((a, b) => Number(a) + Number(b.amount), 0);
    }

    public get totalAmountUnSplitted():string{
        let result=0;
        this.nomStore.chitanta.forEach(mancare=>{
            if(mancare.family.length==0){
                result=Number(result)+Number(mancare.amount)
            }
        });
        return result.toFixed(1);
    }

    public get chitanta(): Array<TMancare> {
        return this.nomStore.chitanta;
    }

    public onCloseMenu(){
        this.leftDrawerOpen=false;
    }

    public getDataFromLocalStorage(){
        const vueInst = this;
        const nomFamiliesLocalStorage=localStorage.getItem("nomFamilies");
        const chitantaLocalStorage=localStorage.getItem("chitanta");
        if(nomFamiliesLocalStorage && nomFamiliesLocalStorage!='undefined'){
            const nomFamiliesTmp=JSON.parse(nomFamiliesLocalStorage);
            vueInst.nomStore.set_families_from_localStorage(nomFamiliesTmp);
            if(nomFamiliesLocalStorage && nomFamiliesLocalStorage!='undefined' && chitantaLocalStorage && chitantaLocalStorage!='undefined') {
                const arrChitanta=JSON.parse(chitantaLocalStorage);
                arrChitanta.forEach((chitanta: TMancare) => {
                    vueInst.nomStore.add_mancare(chitanta);
                })
                vueInst.nomStore.do_calculation('nimic');
            }
            vueInst.$q.loading.hide();
        }else{
            vueInst.$q.loading.hide();
        }
    }

    public created(): void {
        const vueInst = this;
        const $_GET = {id:''};
        vueInst.$q.loading.show();

        if(document.location.toString().indexOf('?') !== -1) {
            const query = document.location
                .toString()
                // get the query string
                .replace(/^.*?\?/, '')
                // and remove any existing hash string (thanks, @vrijdenker)
                .replace(/#.*$/, '')
                .split('&');

            for(let i=0, l=query.length; i<l; i++) {
                const aux = decodeURIComponent(query[i]).split('=');
                if(aux[0]=='id'){
                    $_GET.id = aux[1];
                }

            }
        }

        if($_GET.id  && $_GET.id.toString().length>3){
            fetch("https://grecia.theappsonline.com/getbill.php?id="+$_GET.id.toString(),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "GET"
                })
                .then(res => res.json())
                .then(data => {console.log('getbill.php with data=%o',data);
                    localStorage.setItem("nomFamilies", JSON.stringify(data.nomFamilies));
                    localStorage.setItem("chitanta", JSON.stringify(data.chitanta));
                    vueInst.getDataFromLocalStorage();
                })
                .catch(function(res){ console.log(res) })
        }else{
            vueInst.getDataFromLocalStorage();
        }

    }

}
