import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import {getModule} from 'vuex-module-decorators';
import nomenclatoare from '@/store/nomenclatoare';

@Component({components: {}})
export default class NewFamily extends Vue {
    @Prop() public onCloseNewFamily!: ()=>void;
    public nomStore = getModule(nomenclatoare);
    public FamilyName = '';
    public FamilyNumberOfPersons=1;


    public onSubmit(){
        const vueInst=this;
        vueInst.$q.loading.show();
        const rnd = Math.random();
        this.nomStore.push_new_family({
            appid: rnd.toString(),
            name: vueInst.FamilyName,
            numberOfPersons: vueInst.FamilyNumberOfPersons,
            amount:0
        })
        this.onCloseNewFamily();
    }

    public onReset(){
        this.FamilyName='';
        this.FamilyNumberOfPersons=1;
    }


    public created(): void {
        const vueInst = this;

    }

}
